.social {
  &__title {
    font-weight: 700;
    margin-bottom: 10px; }
  li {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px; }
  a {
    @include flex(center, center);
    background-color: $accent;
    color: $white;
    @include tr(color .3s, background-color .3s);
    width: 40px;
    height: 40px;
    .icon {
      font-size: 20px; }
    @include hover {
      background-color: $white;
      color: $accent; } }

  &--sm {
    li {
      margin-left: 0;
      margin-right: 0;
      @include notlast {
        margin-right: 20px; } }
    a {
      background-color: transparent;
      width: auto;
      height: auto;
      color: $gray-darken;
      @include hover {
        color: $white;
        background-color: transparent;
        box-shadow: 0 0 4px rgba($black, .1); } } } }
