body {
  background: $white;
  line-height: 1.4;
  font-size: $fz;
  min-width: 320px;
  font-family: 'Gilroy', Arial, sans-serif;
  color: $c-text;
  @include no-scroll;
  @include sm {
    font-size: $fz-sm-px; } }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  &.is-loaded:not(.no-fade-in) {
    animation: fadeIn 1s; } }

.container {
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

.out {
  @include md {
    padding-top: $header-height-md; } }


.scroll-down {
  text-transform: uppercase;
  font-size: $fz-sm;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  @include tr(color .3s);
  @include hover {
 }    // color: $accent
  &__title {
    display: block; }
  &__arrow {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 30px;
    &::before,
    &::after {
      content: ''; }
    &::before,
    &::after,
    span {
      position: absolute;
      display: block;
      @include icon-arrow(12, 12, 1, currentColor, -45);
      animation: scrollDown 1s infinite; }
    &::before {
      top: 0; }
    span {
      top: 50%;
      transform: translate(0, -50%) rotate(-45deg);
      animation-delay: .1s; }
    &::after {
      bottom: 0;
      animation-delay: .2s; } } }

.link {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  color: $accent;
  font-weight: 600;
  font-size: $fz-sm;
  padding-right: 45px;
  @include tr(color .3s);
  @include hover {
    @include before {
      transform: translate(10px, -50%); }
    @include after {
      transform: translate(10px, -50%) rotate(225deg) !important; } }
  @include before {
    width: 35px;
    display: inline-block;
    border-top: 1px solid currentColor;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    @include tr(transform .3s); }
  @include after {
    @include icon-arrow(0.6, 0.6, 0.06, currentColor, 225, em);
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%) rotate(225deg) !important;
    @include tr(transform .3s); }
  &--inherit {
    color: inherit; }
  &--no-hover {
    @include hover {
      @include before {
        transform: translate(0, -50%); }
      @include after {
        transform: translate(0, -50%) rotate(225deg) !important; } } } }

.text-label {
  display: inline-block;
  font-size: em(15);
  color: $gray-dark;
  border-color: currentColor;
  border-bottom: 1px solid;
  border-right: 1px solid;
  position: relative;
  text-transform: uppercase;
  padding-top: .5em;
  padding-bottom: .3em;
  padding-right: .3em;
  @include before {
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    border-top: 1px solid currentColor; }
  &--inherit {
    color: inherit; } }

.text-block {
  &__label, &__title, &__text, &__btn {
 }    // opacity: 0
  &__label, &__title {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } }
  &__text {
    margin-bottom: 40px;
    p {
      margin-bottom: 20px;
      @include md {
        margin-bottom: 10px; } }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } } }

.two-cols {
  @include flex(flex-start, normal, row, wrap);
  margin-left: -15px;
  margin-right: -15px;
  &__col {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    @include flex(center, normal, column);
    margin-bottom: 30px;
    @include md {
      width: 100%; }
    @include sm {
      margin-bottom: 15px; } } }

.info-page {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  @include flex(flex-start, normal, row, wrap);
  color: $white;
  @include md {
    height: calc(100vh - #{$header-height-md});
    height: calc((var(--vh, 1vh) * 100) - #{$header-height-md}); }
  @include xs {
    height: auto;
    padding-top: 80px;
    padding-bottom: 30px; }
  &__left, &__right {
    width: 50%;
    @include flex(center, normal, column);
    @include xs {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }
  &__left {
    padding-right: 15px;
    @include xs {
      order: 1; } }
  &__right {
    padding-left: 15px;
    img {
      display: inline-block; }
    @include xs {
      align-items: center;
      order: 0;
      margin-bottom: 30px; } } }
