$fz:       18px;
$fz-sm-px: 16px;
$fz-xs-px: 14px;
$fz-sm:    em(16);
$fz-xs:    em(14);

$c-text: #303030;

$black: #000;
$black-light: #232323;
$white: #fff;

$accent: #00aeef;
$accent-lighten: #cdf1ff;

$gray-lightest: #fbfbfd;
$gray-lighten: #f6f6f6;
$gray: #ebebeb;
$gray-dark: #afafaf;
$gray-darken: #616161;

$padding-page-left: 60px;
$padding-page-left-xll: 15px;

$header-width: 8.5vw;
$header-height-md: 50px;
