.section {
  position: relative;
  &__title {
    text-align: center;
    margin-bottom: 100px;
    @include xl {
      margin-bottom: 80px; }
    @include lg {
      margin-bottom: 60px; }
    @include md {
      margin-bottom: 40px; }
    @include sm {
      margin-bottom: 20px; } }
  &__title-block {
    margin-bottom: 50px;
    text-align: center;
    @include xl {
      margin-bottom: 40px; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &__btn {
    padding-top: 30px;
    text-align: center;
    @include md {
      padding-top: 15px; } }
  &__tabs-nav {
    margin-bottom: 55px;
    @include xl {
      margin-bottom: 40px; }
    @include lg {
      margin-bottom: 30px; } }
  &__pagination {
    margin-top: 60px;
    @include xl {
      margin-top: 40px; }
    @include lg {
      margin-top: 30px; }
    @include md {
      margin-top: 15px; } }
  &--gray-lightest {
    background-color: $gray-lightest; }
  &--black-light {
    background-color: $black-light; }
  &--bg {
    @include cover; }
  &--hero {} }


.section-half-full {
  $this: &;
  position: relative;
  &__left {
    position: absolute;
    overflow: hidden;
    width: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    @include md {
      width: auto;
      position: static; } }
  &__right-in {
    margin-left: 50%;
    @include md {
      margin-left: 0; } }
  &__left-in {
    margin-right: 50%;
    @include md {
      margin-right: 0; } }
  &--right {
    #{$this}__left {
      position: relative;
      // width: 50%
      width: auto;
      left: auto;
      bottom: auto;
      top: auto; }
    #{$this}__right {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      @include md {
        width: auto;
        position: static; } } } }

.section-tabs-nav {
  text-align: center;
  &__btn {
    display: inline-block;
    text-decoration: underline;
    color: $gray-darken;
    border: 1px solid transparent;
    @include tr(color .3s);
    margin-left: 20px;
    margin-right: 20px;
    padding: .2em 2em;
    text-align: center;
    margin-bottom: 5px;
    @include lg {
      margin-left: 10px;
      margin-right: 10px; }
    @include md {
      margin-left: 5px;
      margin-right: 5px; }
    &.is-active {
      color: $white;
      border-color: currentColor;
      text-decoration: none; }
    @include hover {
      @extend .section-tabs-nav__btn.is-active; } } }
