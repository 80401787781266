.works-links {
  @include flex(flex-start, normlal, row, wrap);
  &__item {
    width: 50%;
    @include md {
      width: 100%; } } }

.work-link {
  @include cover;
  position: relative;
  display: block;
  padding: $padding-page-left;
  height: 100%;
  padding-top: 30%;
  color: $white;
  @include hover {
    .link {
      @include before {
        transform: translate(10px, -50%); }
      @include after {
        transform: translate(10px, -50%) rotate(225deg) !important; } } }
  @include xll {
    padding: $padding-page-left-xll;
    padding-top: 30%; }
  @include before {
    @include coverdiv;
    background-color: rgba($black, .4); }
  &__content {
    display: block;
    position: relative;
    z-index: 1; }
  &__upttl {
    font-size: em(15);
    display: block;
    color: $accent; }
  &__title {
    margin-bottom: 25px;
    display: block;
    @include md {
      margin-bottom: 10px; } } }

