.pagination {
  color: $gray-darken;
  text-align: center;
  li {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    margin-right: 8px; }
  a {
    @include tr(color .3s);
    &.is-active {
      color: $white; }
    @include hover {
      color: $white;
      &.prev, &.next {
        background-color: transparent;
        color: $accent;
        border: 1px solid  $accent; } } } }
