.btn {
  $this: &;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  font-size: $fz-sm;
  text-align: center;
  padding: 1.25em;
  padding-left: 2.5em;
  min-width: 210px;
  border-color: currentColor;
  border: 2px solid;
  border-left: 0;
  color: $accent;
  margin-left: 10px;
  @include tr(color .5s);
  @include sm {
    padding-top: 1em;
    padding-bottom: 1em; }
  @include xs {
    min-width: 0;
    width: calc(100% - 10px); }
  @include hover {
    #{$this}__title {
      color: $white; }
    #{$this}__icon {
      color: $white;
      left: .2em;
      width: 25px; }
    #{$this}__cover {
      @include before {
        transform: translate(0, 0); } } }
  &::before, &::after {
    content: '';
    position: absolute;
    left: 0;
    height: 25%;
    width: 2px;
    background-color: currentColor; }
  &::before {
    top: 0; }
  &::after {
    bottom: 0; }
  &__cover {
    @include coverdiv;
    z-index: 0;
    overflow: hidden;
    @include before {
      background-color: currentColor;
      @include coverdiv;
      @include tr(transform .5s);
      transform: translate(0, 100%); } }
  &__title {
    position: relative;
    z-index: 1; }
  &__icon {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -10px;
    transform: translate(0, -50%);
    width: 35px;
    height: 14px;
    @include tr(transform .5s, left .5s, width .5s);
    @include before {
      width: 100%;
      display: inline-block;
      border-top: 1px solid currentColor;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
 }      // +tr(width .5s)
    @include after {
      @include icon-arrow(0.6, 0.6, 0.06, currentColor, 225, em);
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%) rotate(225deg); } }

  &--inherit {
    color: inherit;
    @include hover {
      #{$this}__title {
        color: $accent; }
      #{$this}__icon {
        color: $accent; } } } }
