.footer {
  background-color: $gray-lightest;
  &__inner {
    padding-top: 45px;
    @include lg {
      padding-top: 30px; } }
  &__top {
    @include flex();
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; }
    @include sm {
      display: block;
      text-align: center; } }
  &__logo {
    margin-bottom: 10px; }
  &__text {
    font-size: 13px;
    color: $gray-dark; }
  &__middle {
    width: percentage(6/12);
    text-align: center;
    @include sm {
      width: auto;
      margin-bottom: 10px; } }
  &__nav {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } }
  &__left {
    width: percentage(3/12);
    @include sm {
      width: auto;
      margin-bottom: 10px; } }
  &__contacts {
    width: percentage(3/12);
    text-align: right;
    @include sm {
      width: auto;
      text-align: center;
      margin-bottom: 10px; } }
  &__bottom {
    padding-bottom: 20px;
    text-align: center;
    position: relative;
    @include md {
      padding-bottom: 10px; } }
  &__auth {
    @include flex(flex-start, center);
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    @include lg {
      position: static;
      justify-content: center; }
    a {
      text-decoration: underline;
      color: $accent; } }
  &__auth-icon {
    @include flex(center, center);
    width: 35px;
    height: 35px;
    background-color: $black;
    color: $white;
    margin-right: 10px;
    .icon {
      font-size: 20px; } }
  &__copy {
    @include lg {
      margin-bottom: 10px; } } }


