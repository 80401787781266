.header {
  $this: &;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  height: 100%;
  width: $header-width;
  background-color: transparent;
  transform: translateX(100%);
  @include ready {
    @include tr(height .5s cubic-bezier(.1,.89,.54,1.18)); }
  @include md {
    left: 0;
    bottom: auto;
    width: auto;
    height: $header-height-md;
    background-color: $white;
    @include flex(flex-end); }

  &.is-small {
    height: $header-width * 2;
    .header-btn {
      padding-bottom: 0;
      justify-content: center;
      &__title {
        font-size: 0; } }
    #{$this}__btn {
      transition-delay: .5s;
      @include before {
        transition-delay: .5s; } }
    body:not(.has-open-menu) & {
      @include hover {

          height: 100%;
          .header-btn {
            justify-content: flex-end;
            padding-bottom: 160px;
            @include md {
              padding-bottom: 100px; }
            &__title {
              font-size: inherit; } } } }
    .has-open-menu & {
      height: 100%;
      .header-btn {
        justify-content: flex-end;
        padding-bottom: 160px;
        @include md {
          padding-bottom: 100px; }
        &__title {
          font-size: inherit; } } } }

  &__burger {
    height: $header-width;
    box-shadow: 0px 0px 10.8px 1.2px rgba(0, 0, 1, 0.07);
    @include flex(center, center);
    position: relative;
    z-index: 2;
    background-color: $white;
    @include md {
      height: auto;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      justify-content: flex-end; } }
  &__inner {
    height: calc(100% - #{$header-width});
    @include md {
      height: auto; } }

  &__btn {
    height: 100%;
    position: relative;
    z-index: 2;
    background-color: $gray-lighten;
    overflow: hidden;
    @include tr(color .3s);
    .has-open-menu & {
      color: $white;
      @include before {
        transform: translate(0, 0); } }
    @include hover {
      color: $white;
      @include before {
        transform: translate(0, 0); } }
    @include before {
      @include coverdiv;
      background-color: $accent;
      transform: translate(0, 100%);
      @include ready {
        @include tr(transform .5s); }
      @include md {
        display: none; } }

    @include md {
      background-color: transparent;
      position: static; } }
  &__menu {
    position: absolute;
    right: 100%;
    overflow: auto;
    top: 0;
    bottom: 0;
    width: calc(100vw - #{$header-width});
    z-index: 1;
    background-color: $black-light;
    color: $white;
    transform: translate(110%, 0);
    padding-top: 15px;
    padding-bottom: 15px;
    @include ready {
      @include tr(transform .5s); }
    .container {
      position: relative;
      left: $header-width / 2;
      height: 100%;
      @include r(1730) {
        left: auto; } }
    &.is-active {
      transform: translate(0, 0); }
    @include md {
      width: 100%;
      left: 0;
      bottom: auto;
      top: 100%;
      height: calc(100vh - #{$header-height-md});
      height: calc((var(--vh, 1vh) * 100) - #{$header-height-md});
      transform: translate(0, -100%);
      padding-right: 60px;
      padding-top: 80px; } }
  &__form {
    padding-left: 15px;
    padding-right: 15px;
    overflow-x: hidden;
    @include vertical;
    .contacts-form {
      display: inline-block;
      vertical-align: middle; }
    @include md {
      padding-right: 15px; } } }


.burger {
  display: block;
  color: $black;
  width: 40px;
  height: 40px;
  position: relative;
  @include tr(color .5s);
  @include hover {
    color: $accent; }
  .icon {
    font-size: 35px;
    @include tr(opacity .3s, visibility .3s);
    @include md {
      font-size: 25px; } }
  .icon-left-arrow-chevron {
    @include vcenter;
    visibility: hidden;
    opacity: 0; }
  &.is-active, &.has-close-icon {
    .icon-menu {
      visibility: hidden;
      opacity: 0; }
    .icon-left-arrow-chevron {
      visibility: visible;
      opacity: 1; } } }

.header-btn {
  width: 100%;
  height: 100%;
  @include flex(flex-end, center, column);
  padding-bottom: 160px;
  @include tr(padding .5s);
  position: relative;
  z-index: 1;
  @include md {
    padding-bottom: 100px;
    color: $white;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: calc(#{$header-height-md} + 15px);
    right: 0;
    height: auto;
    width: auto;
    z-index: 2; }
  &.is-active {
    pointer-events: none; }
  .has-open-menu &:not(.is-active) {
    visibility: visible;
    opacity: 1; }

  &__title {
    display: block;
    // transform: translate(calc(50% + .7em), 0) rotate(90deg)
    transform: translate(50%, 0) rotate(90deg);
    transform-origin: left top;
    white-space: nowrap;
    margin-right: -1.4em; }
  &__icon {
    .icon {
      font-size: 24px; } } }
