.s-solutions {
  &__img {
    @include cover;
    padding-top: 60%;
    margin-bottom: 30px; }
  &__link {
    text-align: center; }
  &__right {
 }    // margin-left: -15px
  &__text-block {
    padding-left: percentage(1/6);
    margin-bottom: 85px;
    @include xl {
      padding-left: 15px;
      margin-bottom: 60px; }
    @include lg {
      margin-bottom: 40px; }
    @include md {
      margin-bottom: 20px;
      padding-left: 0; } } }

.s-about {
  color: $white;
  overflow: hidden;
  padding-bottom: 90px;
  @include xl {
    padding-bottom: 45px; }
  @include md {
    padding-bottom: 0; }
  &__inner {
    @include flex;
    @include md {
      display: block; } }
  &__left {
    width: 50%;
    @include md {
      width: auto;
      margin-left: -15px;
      margin-right: -15px; } }
  &__right {
    width: 50%;
    // overflow: hidden
    @include md {
      width: auto;
      margin-left: -15px;
      margin-right: -15px; } }
  &__img-wrap {
    height: 100%;
    margin-right: -75px;
    margin-top: 90px;
    overflow: hidden;
    @include xl {
      margin-top: 45px;
      margin-right: 0; } }
  &__img {
    height: 100%;
    @include cover;
    @include md {
      padding-top: 50%; }
    @include xs {
      padding-top: 70%; } }
  &__content {
    background-color: $accent;
    position: relative;
    padding-top: 75px;
    padding-bottom: 75px;
    padding-right: percentage(1/6);
    @include xl {
      padding-top: 50px;
      padding-bottom: 50px; }
    @include lg {
      padding-top: 40px;
      padding-bottom: 40px;
      padding-right: 15px; }
    @include md {
      padding-left: 15px; }
    @include before {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: $accent;
      width: 50vw;
      right: 100%;
      @include md {
        display: none; } } } }

.s-contacts {
  $this: &;
  overflow: hidden;
  &.section--hero {
    #{$this}__content {
      @include md {
        padding-top: 80px; } } }
  &__content {
    padding-top: 20%;
    padding-bottom: 20%;
    background-color: $accent;
    color: $white;
    position: relative;
    @include md {
      padding-top: 10%;
      padding-bottom: 10%;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: -15px;
      margin-right: -15px;
      @include before {
        display: none; } }


    @include before {
      background-color: $accent;
      top: 0;
      bottom: 0;
      right: 100%;
      width: 50vw;
      position: absolute; } } }

.s-showreel {
  color: $white;
  &__inner {
    position: relative;
    padding-top: 40%;
    @include sm {
      padding-top: 60%; }
    @include xs {
      padding-top: 90%; } }
  &__btn {
    position: absolute;
    top: 50%;
    height: 134px;
    margin-top: -67px;
    // transform: translate(0, -50%)
    right: percentage(1/12); } }
