.solutions-links {
  @include flex; }

.solutions-link {
  $this: &;
  opacity: 0;
  display: block;
  padding: 30px;
  padding-top: 5%;
  border: 1px solid  $gray;
  border-right-color: transparent;
  position: relative;
  width: 33.33333%;
  @include tr(color .3s);
  @include hover {
    color: $white;
    #{$this}__bg {
      visibility: visible;
      opacity: 1;
      transform: scaleY(1.1); }
    &::before {
      top: calc(100% - 53px);
      @include lg {
        top: calc(100% - 43px); }
      @include sm {
        top: calc(100% - 33px); } } }
  @include xll {
 }    // padding-top: 30%
  @include lg {
    padding: 20px;
    padding-top: 5%; }
  @include md {
    padding-top: 5%; }
  @include sm {
    padding: 10px;
    padding-top: 5%; }
  &:last-child {
    border-right: 1px solid $gray; }
  &::before {
    content: '+';
    color: $gray;
    top: 20px;
    right: 30px;
    position: absolute;
    z-index: 1;
    font-size: 1.5em;
    line-height: 1;
    @include tr(top .3s, bottom .3s);
    @include lg {
      right: 10px;
      top: 10px; } }
  &__content {
    position: relative;
    z-index: 1; }
  &__icon {
    display: block;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    @include sm {
      margin-bottom: 10px; }
    .icon {
      font-size: 50px;
      @include lg {
        font-size: 40px; }
      @include sm {
        font-size: 30px; } } }
  &__bg {
    @include coverdiv;
    z-index: 0;
    @include cover;
    @include tr(transform .3s, visibility .3s, opacity .3s);
    visibility: hidden;
    opacity: 0;
    @include before {
      @include coverdiv;
      background-color: rgba($accent, .8); } } }

