$control-btn-size: 70px;
$control-btn-size-md: 50px;
$control-btn-size-sm: 30px;

.prev, .next {
  @include inline-flex(center, center);
  width: $control-btn-size;
  height: $control-btn-size;
  background-color: $accent;
  color: $white;
  @include tr(color .3s, background-color .3s);
  @include before {
    @include icon-arrow(10, 10, 1, currentColor, 45);
    display: block;
    margin-left: .3em; }
  @include hover {
    // background-color: $white
    // color: $accent
    // box-shadow: 0 0 4px rgba($black, .1)
    background-color: transparent;
    color: $accent;
    border: 1px solid  $accent; }
  @include md {
    width: $control-btn-size-md;
    height: $control-btn-size-md; }
  @include sm {
    width: $control-btn-size-sm;
    height: $control-btn-size-sm; } }

.next {
  transform: scaleX(-1); }

.slider {
  &__wrap {
    position: relative;
    .slide {
      font-size: $fz; } }
  &--partners {
    &__wrap {
      .tns-ovh {
        padding-top: 80px;
        padding-bottom: 80px; }
      @include r(1600) {
        padding-left: $control-btn-size + 15px;
        padding-right: $control-btn-size + 15px; }
      @include md {
        padding-left: $control-btn-size-md + 15px;
        padding-right: $control-btn-size-md + 15px;
        .tns-ovh {
          padding-top: 50px;
          padding-bottom: 50px; } }
      @include sm {
        padding-left: $control-btn-size-sm + 5px;
        padding-right: $control-btn-size-sm + 5px; } } }
  &__next, &__prev {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1; }
  &__next {
    right: -$control-btn-size - 15px;
    transform: translate(0, -50%) scaleX(-1);
    @include r(1600) {
      right: 0; } }
  &__prev {
    left: -$control-btn-size - 15px;
    @include r(1600) {
      left: 0; } } }
