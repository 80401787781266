small {
  font-size: inherit; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 700; }

.title {
  line-height: 1;
  font-weight: 700;
  small {
    font-weight: 300;
    &:not(.is-inline) {
      display: block; } }
  &--h1 {
    @include css-lock(28, 57, 320, 1399, font-size); }
  &--h2 {
    @include css-lock(24, 47, 320, 1399, font-size); }
  &--h5 {
    font-size: 1em; }
  &--ttu {
    text-transform: uppercase; }
  &--accent {
    color: $accent; } }

.text {
  &--gray-dark {
    color: $gray-dark; }
  &--xs {
    font-size: $fz-xs; } }
