.contacts-form {
  max-width: 930px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  font-size: $fz;
  color: $white;
  @include xl {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include lg {
    padding-top: 60px;
    padding-bottom: 60px; }
  @include md {
    padding-top: 40px;
    padding-bottom: 40px; }
  @include sm {
    padding-top: 20px;
    padding-bottom: 20px; }
  @include sm {
    font-size: $fz-sm-px; } }

.form {
  &__title {
    text-align: center;
    margin-bottom: 40px;
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &__row {
    margin-left: -15px;
    margin-right: -15px;
    @include flex(flex-start, normal, row, wrap);
    @include md {
      margin-left: -5px;
      margin-right: -5px; } }

  &__col {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    @include notlast {
      margin-bottom: 20px;
      @include md {
        margin-bottom: 10px; } }
    @include md {
      padding-left: 5px;
      padding-right: 5px; }
    @include xs {
      width: 100%; } }
  &__col-full {
    width: 100%; }
  &__btn {
    text-align: right; } }

.input, .textarea {
  font-weight: 300;
  text-align: left;
  input, textarea {
    border: 1px solid  $gray-darken;
    height: 50px;
    padding: .6em 1.1em;
    width: 100%;
    background-color: transparent;
    @include tr(color .3s, border-color .3s);
    @include placeholder {
      color: $gray-darken; }
    &:focus {
      border-color: currentColor;
      @include placeholder {
        color: currentColor; } }
    @include sm {
      height: 40px;
      padding: .4em .9em; } }
  label {
    display: block;
    color: $accent;
    margin-bottom: .3em; }
  textarea {
    min-height: 280px;
    overflow: hidden;
    @include xl {
      min-height: 250px; }
    @include lg {
      min-height: 200px; }
    @include md {
      min-height: 160px; } } }
