.contacts-sm {
  .icon {
    position: relative;
    margin-left: .3em;
    top: .2em;
    font-size: .9em; }
  li {
    @include notlast {
      margin-bottom: 5px; } } }
.map {
  position: relative;
  background-color: $gray;
  // padding-top: 70%
  height: 100%;
  @include md {
    padding-top: 60%; }
  @include xs {
    padding-top: 80%; }
  iframe {
    @include coverdiv;
    z-index: 1; } }
