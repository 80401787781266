.menu {
  height: 100%;
  @include flex();
  @include xs {
    display: block; }
  &__left {
    width: 50%;
    padding-right: 15px;
    @include flex(center, normal, column);
    @include xs {
      width: auto;
      padding-right: 0;
      margin-bottom: 30px; } }
  &__right {
    width: 50%;
    padding-left: 15px;
    @include flex(center, normal, column);
    @include xs {
      width: auto;
      padding-left: 0; } }
  &__text-block {
    position: relative;
    margin-bottom: 40px;
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; }
    @include after {
      border-top: 1px solid  $gray-darken;
      width: 100px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1; } } }
