.hero {
  $padding-bottom: 70px;
  color: $white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  padding-top: 30px;
  overflow: hidden;
  @include flex(space-between, normal, column);
  @include md {
    height: calc(100vh - #{$header-height-md});
    height: calc((var(--vh, 1vh) * 100) - #{$header-height-md}); }
  &__bg {
    @include cover;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: $padding-bottom;
    @include before {
      @include coverdiv;
      z-index: 0;
      background-color: rgba(0,0,0,0.6);
      background: linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%); } }
  &__top {
    width: 100%;
    padding-left: calc(#{$header-width} + 15px);
    padding-right: calc(#{$header-width} + 15px);
    position: relative;
    z-index: 1;
    @include md {
      padding-left: 0;
      padding-right: 0; } }

  &__block {
    opacity: 0;
    width: 50%;
    position: relative;
    z-index: 1;
    @include md {
      width: 100%; } }
  &__show-btn {
    position: absolute;
    z-index: 1;
    left: 60%;
    bottom: 30%;
    @include md {
      position: static;
      text-align: center; } }
  &__phone {
    text-align: right; }

  &--black-light {
    background-color: $black-light; } }

.hero-block {
  background-color: $accent;
  padding: $padding-page-left 100px 100px $padding-page-left;
  @include xll {
    padding: $padding-page-left-xll 50px 50px $padding-page-left-xll; }
  &__title {
    margin-bottom: 20px;
    // opacity: 0
    @include md {
      margin-bottom: 10px; } }
  &__subttl {
    margin-bottom: 15px;
    color: $accent-lighten;
    // opacity: 0
    @include md {
      margin-bottom: 10px; } }
  &__scroll {
 } }    // opacity: 0

.show-btn {
  font-weight: 700;
  font-size: 13px;
  &__btn {
    display: inline-block;
    text-align: center; }
  &__icon {
    display: block; }
  &__title {
    display: block; } }
