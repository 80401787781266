.nav {
  color: $gray-darken;
  li {
    @extend .title;
    @extend .title--h1;
    @include notlast {
      margin-bottom: 30px;
      @include md {
        margin-bottom: 15px; } } }
  a {
    @include tr(color .3s);
    @include hover {
      color: $white; }
    &.is-active {
      color: $white; } }

  &--footer {
    color: inherit;
    li {
      display: inline-block;
      margin-left: 30px;
      margin-right: 30px;
      font-size: $fz !important;
      font-weight: 300;
      margin-bottom: 0 !important;
      @include lg {
        margin-left: 15px;
        margin-right: 15px; }
      @include md {
        margin-left: 5px;
        margin-right: 5px; } }
    a {
      @include hover {
        color: $accent; }
      &.is-active {
        color: $accent; } } } }

