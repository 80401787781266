.features {
  $this: &;
  @include flex(center, normal, row, wrap);
  margin-left: -15px;
  margin-right: -15px;
  @include md {
    margin-left: -10px;
    margin-right: -10px; }
  @include xs {
    margin-left: -5px;
    margin-right: -5px; }
  &__item {
    opacity: 0;
    padding-left: 15px;
    padding-right: 15px;
    width: 33.33333%;
    margin-bottom: 70px;
    @include xl {
      margin-bottom: 50px; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px;
      width: 50%;
      padding-left: 10px;
      padding-right: 10px; }
    @include xs {
      padding-left: 5px;
      padding-right: 5px; } }
  &--grid {
    margin-left: 0;
    margin-right: 0;
    position: relative;
    justify-content: flex-start;
    @include after {
      width: 100%;
      position: absolute;
      height: 1px;
      bottom: 0;
      z-index: 1;
      background-color: $white; }
    #{$this}__item {
      border-right: 1px solid  $gray;
      border-bottom: 1px solid  $gray;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 30px;
      padding-bottom: 30px;
      &:nth-child(3n -3) {
        border-right: 0;
        @include md {
          border-right: 1px solid $gray; }
        // +xs
 }        //   border-right: 0
      @include md {
        &:nth-child(even) {
          border-right: 0; } }
      // +xs
 } } }      //// border-right: 0


.feature {
  &__icon {
    color: $accent;
    margin-bottom: 15px;
    padding-top: 25px;
    padding-left: 25px;
    position: relative;
    display: inline-block;
    @include image('feature.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: auto;
    .icon {
      font-size: em(50); }
    @include xs {
      padding-left: 10px;
      padding-top: 10px;
      background-size: 100%;
      .icon {
        font-size: em(40); } } }
  &__title {
    margin-bottom: 10px; }
  &__text {
    font-size: $fz-xs;
    color: $gray-dark; }
  &--sm {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px; } }
