.project {
  $this: &;
  position: relative;
  color: $white;
  &__right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    overflow: hidden;
    @include md {
      width: auto;
      position: static; } }

  &--transp {
    color: inherit; }
  &--reverse {
    #{$this}__right {
      right: auto;
      left: 0; }
    #{$this}__content {
      margin-right: 0;
      margin-left: 50%;
      padding-right: 0;
      padding-left: percentage(1/12);
      @include lg {
        padding-left: 15px; }
      @include md {
        margin-left: 0;
        padding-left: 0; } } }
  &__content {
    margin-right: 50%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: percentage(1/12);
    @include flex(center, normal, column);
    min-height: 700px;
    .is-ie & {
      min-height: 0;
      height: 700px; }
    @include xl {
      min-height: 600px;
      .is-ie & {
        min-height: 0;
        height: 600px; } }
    @include lg {
      padding-right: 15px;
      min-height: 500px;
      .is-ie & {
        min-height: 0;
        height: 500px; } }
    @include md {
      margin-right: 0;
      min-height: 0;
      padding-right: 0;
      height: auto; } }

  &__img {
    height: 100%;
    @include cover;
    @include md {
      height: auto;
      padding-top: 50%; } } }
