.showreel {
  $this: &;
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $black-light;
  visibility: hidden;
  opacity: 0;
  color: $white;
  // padding: 15px
  @include vertical;
  @include ready {
    @include tr(opacity .3s, visibility .3s); }

  &.is-active {
    visibility: visible;
    opacity: 1;
    transition-delay: .7s;
    #{$this}__video {
      display: block; } }
  &__inner {
    display: inline-block;
    vertical-align: middle;
    // font-size: $fz
    @include sm {
 } }      // font-size: $fz-sm-px
  &__close {
    @include icon-close(30, 1, currentColor);
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    @include tr(color .3s);
    @include hover {
      color: $accent; } }
  &__video {
    display: none;
    video {
      width: 100%;
      max-height: 100vh;
      max-height: calc(var(--vh, 1vh) * 100); } } }

.popup-overlay {
  position: fixed;
  z-index: 20;
  background-color: $black-light;
  border-radius: 50%;
  // +ready
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
  @include scale(0);
  @include tr(transform 1s); }
